export default {
    methods: {
        adjustQuantity(quantity, step) {
            if (step <= 1) {
                return quantity;
            }

            if (quantity <= step) {
                return step;
            }

            if (quantity >= step) {
                const mod = quantity % step;
                quantity = mod ? quantity + (step - mod) : quantity;
            }

            return quantity;
        }
    }
};
