import { defineStore } from 'pinia'

export const useWeightStore = defineStore('weight', {
    state: () => ({
        unit: 'oz',
        rate: 31.1035
    }),
    actions: {
        format(fine_weight) {
            if (!fine_weight) {
                return '';
            }
            let weight = (fine_weight * 100) / 100;
            weight = this.unit === 'oz' ? (weight / this.rate) : weight;

            return Math.round(weight*100)/100 + this.unit;
        },
        setUnit(unit) {
            if (!unit) {
                return;
            }
            this.unit = unit;
        }
    }
});