<template>
    <button type="button" class="btn btn-outline-primary btn-lg w-100" @click="notifyModal()">
        {{ $t('Notify me') }}
    </button>
</template>

<script setup>
</script>

<script>
import ProductNotifyModal from "../../Modals/ProductNotifyModal.vue";


export default {
    props: {
        product_id: Number,
    },
    methods: {
        notifyModal() {
            useModal().openModal('ProductNotifyModal', ProductNotifyModal, {product_id: this.product_id});
            // this.$modals.open(ProductNotifyModal, {product_id: this.product_id});
        },
    },
};
</script>
