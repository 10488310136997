import {useClientFetch} from "../composables/useClientFetch.js";
import {getFromClient} from "../composables/getFromClient.js";

export default {
    rate(id, value, title, comment, modal = false) {
        return useClientFetch(`/products/${id}/rating/`, {
            method: 'POST',
            body: {
                value,
                title,
                comment,
                modal
            }
        })
    },
    notify(id, email) {
        return useClientFetch(`products/${id}/notify`, {
            method: 'POST',
            body: {
                email
            }
        })
    },
    ratings(id, sort, page, perPage) {
        return useClientFetch(`/products/${id}/rating?sort=${sort}&page=${page}&perPage=${perPage}`);
    }
};
