<template>
    <button class="watchlist-button btn btn-outline-primary btn-lg" @click="toggleWatchlist" onclick="return!1;">
        <img v-if="watchlistStore.hasProduct(product_id)" class="size-18" loading="lazy" src="@img/i-heart-added.svg" alt="{{ $t('Added to Watchlist') }}">
        <img v-else class="size-18" loading="lazy" src="@img/i-heart.svg" alt="{{ $t('Add to Watchlist') }}">
    </button>
</template>

<script setup>
import {useWatchlistStore} from '~/stores/WatchlistStore';
import ModalContent from "../../Modals/ModalContent.vue";

const watchlistStore = useWatchlistStore();
</script>

<script>
export default {
    props: {
        product_id: Number,
    },
    methods: {
        toggleWatchlist() {
            useWatchlistStore().toggleProduct(this.product_id);
        },
    },
};
</script>
