import {defineStore} from 'pinia';
import {ref, computed} from 'vue';

import api from '../apis/cart.js';
import {useClientFetch} from '../composables/useClientFetch.js';
import QuantityStepMixin from "../mixins/QuantityStepMixin.js";
import {getFromServer} from "../composables/getFromServer.js";
import ProductAddedToCart from "../components/Modals/ProductAddedToCart.vue";
import {useModal} from "./ModalStore.js";

export const useCartStore = defineStore('cart', () => {
    const items = ref([]);
    const count = computed(() => items.value.reduce((qty, item) => qty + item.quantity, 0));

    function initData(data){
        items.value = data;
    }

    async function fetchCart() {
        if (items.value.length > 0) {
            return;
        }

        items.value = await getFromServer('cart');
    }

     function addToCart(product_id, quantity = 1) {
        storeUpdate(product_id, quantity);

        return api.storeUpdate(product_id, quantity);
    }

    function addToCartSilent(product_id, quantity = 1) {
        storeUpdate(product_id, quantity);

        return api.store(product_id, quantity);
    }

    async function updateCart(id, quantity, silent = true) {
        if (silent) {
            storeUpdate(id, quantity);
        } else {
            storeUpdate(id, quantity + getProductQuantity(id));
        }

        return api.storeUpdate(id, getProductQuantity(id));
    }

    async function remove(id) {
        const index = getProductIndex(id);
        if (index !== -1) {
            items.value.splice(index, 1);
            await api.destroy(id);
        }
    }

    function hasProduct(id) {
        return getProductQuantity(id) > 0;
    }

    function getProductIndex(id) {
        return items.value.findIndex(item => item.id === id);
    }

    function getProductQuantity(product_id) {
        const item = items.value.find(item => item.id === product_id);
        return item ? item.quantity : 0;
    }

    function storeUpdate(id, quantity) {
        let index = getProductIndex(id);
        if (index !== -1) {
            items.value[index].quantity = quantity;
        } else {
            items.value.push({ id, quantity });
        }
    }

    async function storeUpdateSave(id, quantity, silent = true, step = 1) {
        quantity = QuantityStepMixin.methods.adjustQuantity(quantity, step);
        let response = null;
        if (hasProduct(id)) {
            if (quantity > 0) {
                response = await updateCart(id, quantity, silent);
            } else {
                remove(id);
            }
        } else {
            response = await addToCart(id, quantity, silent);
        }

        if (!silent && response) {
            useModal().openModal('ProductAddedToCart', ProductAddedToCart, response.data);
        }
    }

    const debounceTimers = ref([]);
    function debouncedChange(id) {
        if (!debounceTimers.value[id]) {
            debounceTimers.value[id] = debounce(async () => {
                await api.storeUpdate(id, getProductQuantity(id));
                delete debounceTimers.value[id];
            });
        }
        debounceTimers.value[id]();
    }
    function debounce(func, delay = 750) {
        let debounceTimer;
        return function(...args) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(this, args), delay);
        };
    }

    return {
        items,
        initData,
        fetchCart,
        addToCart,
        addToCartSilent,
        updateCart,
        remove,
        storeUpdateSave,
        hasProduct,
        getProductQuantity,
        count
    };
});
