import {useClientFetch} from "../composables/useClientFetch.js";

export default {
    index() {
        return useClientFetch('/cart/').data;
    },
    async storeUpdate(product_id, quantity) {
        const response = await useClientFetch('cart',
            {
                method: 'POST',
                body: {product_id, quantity}
            });

        //update dataLayer
        if (response && 'dataLayer' in response.data) {
            gtm.push(response.data.dataLayer);
        }

        return response;
    },
    async destroy(product_id) {
        const response = await useClientFetch(`cart/${product_id}`,
            {
                method: 'DELETE',
            });

        //update dataLayer
        if (response && 'dataLayer' in response.data) {
            gtm.push(response.data.dataLayer);
        }

        return response;
    },
};
