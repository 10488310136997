<template>
    <span class="product-label">{{ $t('products.labels.'+text) }}</span>
</template>

<script setup>
const props = defineProps({
    text: {
        required: true,
        type: String
    }
})
</script>


<script>
export default {
    props: {
        text: {
            required: true,
            type: String
        }
    }
}
</script>

