<template>
    <template v-if="!small && cartStore.hasProduct(product.id)">
        <a v-if="showTrash" class="btn btn-grey btn-lg" @click.prevent="cartStore.remove(product.id)" href="#"><img class="size-18 has-dark" src="@img/i-delete.svg" alt="remove"></a>
        <div class="d-flex justify-content-center align-content-center input-group">
            <button class="btn btn-lg btn-grey product_quantity_btn_minus" type="button" @click.prevent="updateQuantity(-quantityStep)">-</button>
            <input class="form-control form-control-lg text-center no-arrows mw-100"
                   :value="cartStore.getProductQuantity(product.id)"
                   @keyup="updateQuantityInput($event)"
                   maxlength="7"
            >
            <button class="btn btn-lg btn-grey product_quantity_btn_plus" type="button" @click.prevent="updateQuantity(quantityStep)">+</button>
        </div>
    </template>

    <template v-else>
        <button :class="`btn btn-primary btn-${size} w-100`" @click.prevent="cartStore.storeUpdateSave(product.id, quantityStep, false)">
            <img v-if="small" src="@img/i-cart-w.svg" loading="lazy" alt="{{ $t('Add to cart') }}">
            <span v-else>{{ $t('Add to cart') }}</span>
        </button>
    </template>
</template>

<script setup>
import {useCartStore} from '~/stores/CartStore';
const cartStore = useCartStore()
</script>

<script>
import QuantityStepMixin from "~/mixins/QuantityStepMixin.js";
import {useProductQuantityStore} from "../../../stores/ProductQuantityStore.js";

export default {
    mixins: [QuantityStepMixin],
    props: {
        product: {
            required: true,
            type: Object
        },
        icon: Boolean,
        size: {
            default: 'lg',
            type: String
        },
        small: {
            type: Boolean,
            default: false
        },
        showTrash: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        quantityStep() {
            return this.product.order_quantity_step || 1;
        }
    },
    methods: {
        updateQuantity(quantity) {
            quantity += useCartStore().getProductQuantity(this.product.id);
            quantity = this.adjustQuantity(quantity, this.quantityStep);
            useCartStore().updateCart(this.product.id, quantity);
            useProductQuantityStore().setQuantity(this.product.id, quantity);
        },
        updateQuantityInput(e) {
            let quantity = this.adjustQuantity(parseFloat(e.target.value), this.quantityStep);
            useCartStore().updateCart(this.product.id, quantity);
            useProductQuantityStore().setQuantity(this.product.id, quantity);
        },
    },
};
</script>
