import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useClientFetch } from '../composables/useClientFetch.js';
import {useUserStore} from "./UserStore.js";
import {useModal} from "./ModalStore.js";
import WatchlistCreateAccountModal from "../components/Modals/WatchlistCreateAccountModal.vue";
import {gtm} from '../composables/gtm.js';

export const useWatchlistStore = defineStore('watchlist', () => {
    const products = ref([]);
    const count = computed(() => products.value.length);

    function initData(data) {
        products.value = data;
    }

    async function fetchWatchlist() {
        if (!isUser() || products.value.length > 0) {
            return;
        }

        products.value = await getFromServer('watchlist');
    }

    async function toggleProduct(id) {
        if (!useUserStore().isLoggedIn()) {
            useModal().openModal('WatchlistCreateAccountModal', WatchlistCreateAccountModal);
            return;
        }
        if (hasProduct(id)) {
            useClientFetch(`watchlist/${id}`, {
                method: 'DELETE'
            }).then(response => {
                if (response.success) {
                    products.value = products.value.filter(item => item.id !== id);
                }
            });
        } else {
            useClientFetch('watchlist', {
                method: 'POST',
                body: { product_id: id }
            }).then(response => {
                if (response.success) {
                    products.value.push({ id: id, quantity: 1 });

                    //update dataLayer
                    if (response && 'dataLayer' in response.data) {
                        gtm.push(response.data.dataLayer);
                    }
                }
            });
        }
    }

    function isUser() {
        return true;
        // return document.body.classList.contains('user-logged');
    }

    function hasProduct(id) {
        return products.value.findIndex(item => item.id === id) >= 0;
    }

    return {
        products,
        count,
        initData,
        toggleProduct,
        hasProduct,
        fetchWatchlist
    };
});
