import { defineStore } from 'pinia'
import {ref} from 'vue';


export const useProductQuantityStore = defineStore('product_quantity_store', () => {
    const quantities = ref({});

    const setQuantity = function (product_id, quantity) {
        quantities.value[product_id] = quantity;
    }

    const updateQuantity = function (product_id, newQuantity) {
        this.setQuantity(product_id, newQuantity);
    }

    function getPriceBasedOnQuantity(product, quantity) {
        const quantityPrices = product.quantity_prices;

        if (quantityPrices.length <= 0) {
            return;
        }

        return quantityPrices
            .slice()
            .reverse()
            .find(price => price.quantity <= quantity);
    }

    return {
        quantities,
        setQuantity,
        updateQuantity,
        getPriceBasedOnQuantity
    };
});
